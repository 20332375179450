<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "คลังรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "คลังรถ",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "คลังรถ",
          active: false,
        },
      ],
      rowsSelect: [],
      loading: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      rowsRo: [],
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      type: "",
      roId: "",
      invId: "",
      serviceRo: [],
      selectMode: "single",
      userBranchId: "",
      filter: {
        prodCode: "",
        prodNameTh: "",
        prodNameEn: "",
        modelNameTh: "",
        modelNameEn: "",
        userBranchId: "",
        stock: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      rowsStock: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "prodCode",
          sortable: true,
          label: "รหัสสินค้า",
        },
        // {
        //   key: "productNameTh",
        //   sortable: true,
        //   label: "ชื่อสินค้า(ไทย)",
        // },
        {
          key: "productNameEn",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        // {
        //   key: "modelNameTh",
        //   sortable: true,
        //   label: "ชื่อรุ่น(ไทย)",
        // },
        {
          key: "modelNameEn",
          sortable: true,
          label: "ชื่อรุ่น",
        },
        {
          key: "cost",
          sortable: true,
          label: "ราคาทุน",
          class: "text-end",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
          class: "text-end",
        },
        {
          key: "retailPrice",
          sortable: true,
          label: "ราคาปลีก",
          class: "text-end",
        },
        {
          key: "statusLabel",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "stockNameTh",
          sortable: true,
          label: "คลัง",
        },
        // {
        //   key: "action",
        //   sortable: false,
        //   label: "แก้ไข|ลบ",
        // },
      ],
      overlayFlag: false,
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.filter.userBranchId = user.userAccessBranch[0];
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getDataStock();
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    mapStatus(status) {
      if (status == "A") {
        return this.rows.statusLabel.A;
      } else if (status == "DEL") {
        return this.rows.statusLabel.DEL;
      } else if (status == "S") {
        return this.rows.statusLabel.S;
      } else if (status == "WXFR") {
        return this.rows.statusLabel.WXFR;
      } else if (status == "XFR") {
        return this.rows.statusLabel.XFR;
      }
    },
    getData: function() {
      this.loading = true;
      useNetw
        .get("api/stock-vehicle", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            stockId:
              this.filter.stock != null ? this.filter.stock.stockId : undefined,
            prodCode: this.filter.prodCode,
            prodNameTh: this.filter.prodNameTh,
            prodNameEn: this.filter.prodNameEn,
            modelNameTh: this.filter.modelNameTh,
            modelNameEn: this.filter.modelNameEn,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
          this.rows.statusLabel = response.data.statusLabel;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },
    getDataStock: function() {
      this.loading = true;
      useNetw
        .get("api/master/stock-vehicle", {
          params: {
            branchId: this.filter.userBranchId.branchId,
          },
        })
        .then((response) => {
          this.rowsStock = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.alertConfirm(this.selected.roId);
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <!-- <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        > -->

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code> * </code> สาขา:
                      <multiselect
                        v-model="filter.userBranchId"
                        label="nameTh"
                        type="search"
                        :options="optionBranch"
                        :show-labels="false"
                        open-direction="bottom"
                        placeholder=""
                        @input="getDataStock"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code> * </code> คลัง:
                      <multiselect
                        v-model="filter.stock"
                        label="nameTh"
                        type="search"
                        :options="rowsStock"
                        :show-labels="false"
                        open-direction="bottom"
                        placeholder=""
                        @input="getData"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      รหัสสินค้า:
                      <b-form-input
                        v-model="filter.prodCode"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อสินค้า(ไทย):
                      <b-form-input
                        v-model="filter.prodNameTh"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อสินค้า(อังกฤษ):
                      <b-form-input
                        v-model="filter.prodNameEn"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อรุ่น(ไทย):
                      <b-form-input
                        v-model="filter.modelNameTh"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อรุ่น(อังกฤษ):
                      <b-form-input
                        v-model="filter.modelNameEn"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <b-button
                  class="btn float-end m-1"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search-alt"></i>
                  ค้นหา
                </b-button>
                <!-- <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    :to="{
                      name: 'create-invoice',
                    }"
                  >
                    <i class="uil-plus"></i>
                    สร้างใบแจ้งหนี้
                  </b-button> -->
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="handlePageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <div class="table-responsive mb-0" v-if="rows.length > 0">
                <b-table
                  :items="rows"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                >
                  <template #cell(index)="rows">
                    {{ rows.index + 1 }}
                  </template>
                  <template #cell(statusLabel)="rowRo">
                    <span class="badge bg-success" v-if="rowRo.item.status == 'A'">
                      {{ mapStatus(rowRo.item.status) }}
                    </span>
                    <span class="badge bg-danger" v-if="rowRo.item.status == 'DEL'">
                      {{ mapStatus(rowRo.item.status) }}
                    </span>
                    <span class="badge bg-warning" v-if="rowRo.item.status == 'S'">
                      {{ mapStatus(rowRo.item.status) }}
                    </span>
                    <span class="badge bg-info" v-if="rowRo.item.status == 'WXFR'">
                      {{ mapStatus(rowRo.item.status) }}
                    </span>
                    <span class="badge bg-primary" v-if="rowRo.item.status == 'XFR'">
                      {{ mapStatus(rowRo.item.status) }}
                    </span>
                  </template>
                  <!-- <template v-slot:cell(action)="rowsRo">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-invoiceId',
                              params: { invId: rowsRo.item.invId },
                            }"
                            title="View"
                          >
                            <i class="uil uil-eye font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alertDelete(rowsRo.item.invId)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getInvoicePrint(rowsRo.item.invId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template> -->
                </b-table>
              </div>
              <div class="div" v-else>
                <div class="text-center"><b>ไม่พบข้อมูล</b></div>
              </div>
            </b-skeleton-wrapper>
            <div class="row">
              <div class="col">
                <div class="align-items-center">
                  แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                  {{ this.rows.total }} รายการ
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows.total"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- </b-overlay> -->
      </div>
    </div>
  </Layout>
</template>
